@tailwind base;
@tailwind components;
@tailwind utilities;

/** 
 * The following definitions are a temporary fix for older Safari versions, since Tailwind
 * uses syntax that these browsers don't accept. More specifically, the definition of
 * .bg-white in Tailwind 3 is: background-color: rgb(255 255 255/var(--tw-bg-opacity));
 * and similar for the other definitions.
 */
.bg-white {
    background-color: white;
}

.text-white {
    color: white;
}

.bg-gray-100 {
    background-color: #f3f4f6;
}

.bg-exam-blue {
    background-color: #162234;
}
